'use client'

import { FormEventHandler, useEffect, useRef } from 'react'
import { Button, Dialog, Divider, InfoBanner, Navigation, Note, Text } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'

import {
  buyerWantItemEvent,
  openOfferScreenEvent,
  startMakeOfferRequestEvent,
  viewScreenEvent,
} from '_libs/common/event-tracker/events'
import { getHomepageSessionIdFromUrl } from '_libs/utils/homepage'
import { getSearchSessionData } from '_libs/utils/search'
import { SERVICE_FEE_HELP_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { CurrencyAmountModel, OfferRequestPriceSuggestionModel } from 'types/models'
import { OfferPriceSuggestionOption } from 'types/conversation'

import OfferSubmitButton from '../OfferSubmitButton/OfferSubmitButton'
import { OfferPriceField } from '../fields/OfferPriceField'
import OfferPriceWithSuggetionsField from '../fields/OfferPriceWithSuggetionsField'
import ItemSummary from '../ItemSummary/ItemSummary'

type Props = {
  maxDiscount?: string
  transactionId?: number
  remainingOffers?: number
  offerPriceSuggestions?: Array<OfferRequestPriceSuggestionModel> | null
  show: boolean
  minItemPrice?: CurrencyAmountModel
  maxItemPrice?: CurrencyAmountModel
  thresholdPrice: string | null
  isSubmitting: boolean
  itemPrice: CurrencyAmountModel
  onSubmit: FormEventHandler
  onClose: () => void
  itemId: number
  itemTitle?: string
  itemThumbnailUrl?: string | null
  orderItemIds?: Array<number>
  offerRequestSessionId?: string
  selectedOfferRequestPriceSuggestion?: OfferPriceSuggestionOption
  onSelectOfferRequestPriceSuggestion?: (offerPriceSuggestion: OfferPriceSuggestionOption) => void
}

const BuyerOfferRequestDialog = ({
  show,
  onClose,
  onSubmit,
  itemPrice,
  maxDiscount,
  isSubmitting,
  minItemPrice,
  maxItemPrice,
  transactionId,
  thresholdPrice,
  remainingOffers,
  offerPriceSuggestions,
  itemId,
  itemTitle,
  itemThumbnailUrl,
  orderItemIds,
  offerRequestSessionId,
  selectedOfferRequestPriceSuggestion,
  onSelectOfferRequestPriceSuggestion,
}: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('conversation.hero_message.make_offer')

  const isOfferRequestPriceSuggestionEnabled = !!offerPriceSuggestions
  const thresholdBannerKey = 'offer_modal.threshold_banner_title.buyer'

  const { screen } = useSession()
  const { searchParams } = useLocation()
  const referrerScreen = searchParams.referrer?.toString()
  const searchSessionData = getSearchSessionData()
  const searchSessionIdRef = useRef(
    referrerScreen === Screen.Catalog ? searchSessionData.searchSessionId : null,
  )

  useEffect(() => {
    if (!show) return

    track(viewScreenEvent({ screen: Screen.BuyerOriginatingOffer }))
  }, [show, track])

  useEffect(() => {
    if (!show || !offerRequestSessionId || !transactionId) return

    track(
      startMakeOfferRequestEvent({
        offerRequestSessionId,
        currentPrice: Number(itemPrice.amount),
        hasSuggestedPrices: isOfferRequestPriceSuggestionEnabled,
        transactionId: transactionId.toString(),
      }),
    )

    track(
      buyerWantItemEvent({
        itemId,
        transactionId,
        button: 'make_offer',
        globalSearchSessionId: searchSessionData.globalSearchSessionId,
        globalCatalogBrowseSessionId: searchSessionData.globalCatalogBrowseSessionId,
        searchSessionId: searchSessionIdRef.current,
        homepageSessionId: getHomepageSessionIdFromUrl(),
      }),
    )
  }, [
    isOfferRequestPriceSuggestionEnabled,
    itemPrice.amount,
    offerRequestSessionId,
    show,
    track,
    transactionId,
    itemId,
    itemPrice,
    searchSessionData,
  ])

  useEffect(() => {
    if (screen !== Screen.Item || !transactionId || !show) return

    track(
      openOfferScreenEvent({
        isBuyer: true,
        currentPrice: Number(itemPrice.amount),
        currencyCode: itemPrice.currencyCode,
        openedFrom: Screen.ItemPage,
        transactionId,
      }),
    )
  }, [screen, itemPrice, transactionId, show, track])

  function renderNavigation() {
    return (
      <Navigation
        body={translate('offer_modal.title')}
        right={
          <Button
            inverse
            inline
            iconName={X24}
            testId="offer-modal-navigation-close-button"
            onClick={onClose}
          />
        }
        testId="offer-modal-navigation"
      />
    )
  }

  function renderRemainingOffersNote() {
    if (!remainingOffers) return null

    return <Note text={translate('offer_modal.caption', { remaining_offers: remainingOffers })} />
  }

  return (
    <Dialog show={show} testId="offer-modal">
      <form onSubmit={onSubmit}>
        {renderNavigation()}
        <Divider />
        {thresholdPrice && !isOfferRequestPriceSuggestionEnabled && (
          <InfoBanner
            body={
              <Text
                as="span"
                text={translate(thresholdBannerKey, {
                  amount: thresholdPrice,
                  'learn-more': chunks => (
                    <a href={SERVICE_FEE_HELP_URL} target="_blank" rel="noreferrer">
                      {chunks}
                    </a>
                  ),
                })}
                html
              />
            }
            type={InfoBanner.Type.Info}
          />
        )}
        <ItemSummary
          price={itemPrice}
          title={itemTitle}
          thumbnailUrl={itemThumbnailUrl}
          itemCount={orderItemIds?.length}
        />
        {isOfferRequestPriceSuggestionEnabled ? (
          <OfferPriceWithSuggetionsField
            maxDiscount={maxDiscount}
            minItemPrice={minItemPrice}
            maxItemPrice={maxItemPrice}
            currentItemPrice={itemPrice}
            transactionId={transactionId}
            offerPriceSuggestions={offerPriceSuggestions}
            itemId={itemId}
            orderItemIds={orderItemIds}
            offerRequestSessionId={offerRequestSessionId}
            selectedOfferRequestPriceSuggestion={selectedOfferRequestPriceSuggestion}
            onSelectOfferRequestPriceSuggestion={onSelectOfferRequestPriceSuggestion}
          />
        ) : (
          <OfferPriceField
            maxDiscount={maxDiscount}
            minItemPrice={minItemPrice}
            maxItemPrice={maxItemPrice}
            currentItemPrice={itemPrice}
            itemId={itemId}
            orderItemIds={orderItemIds}
          />
        )}
        {thresholdPrice && isOfferRequestPriceSuggestionEnabled && (
          <InfoBanner
            body={
              <Text
                as="span"
                text={translate(thresholdBannerKey, {
                  amount: thresholdPrice,
                  'learn-more': chunks => (
                    <a href={SERVICE_FEE_HELP_URL} target="_blank" rel="noreferrer">
                      {chunks}
                    </a>
                  ),
                })}
                html
              />
            }
            type={InfoBanner.Type.Info}
          />
        )}
        {renderRemainingOffersNote()}
        <OfferSubmitButton
          currency={itemPrice.currencyCode}
          isSubmitting={isSubmitting}
          isOfferPriceDisplayEnabled={isOfferRequestPriceSuggestionEnabled}
          isBuyer
        />
      </form>
    </Dialog>
  )
}

export default BuyerOfferRequestDialog
