'use client'

import { useCallback, useMemo, useState } from 'react'

import { createItemConversationThread, getTransaction, translateConversation } from 'data/api'

import * as api from 'data/api'

import { UiState } from '@marketplace-web/shared/ui-helpers'

import { transformTransaction, transformConversationResponse } from 'data/transformers'

import { HttpStatus } from 'data/api/response-codes'

import { TransactionModel } from 'types/models'

import { Conversation } from 'types/models/conversation'

import {
  SendMessageUiState,
  GetConversationRequestArgs,
  TranslateConversationArgs,
  GetConversationUiState,
  GetTransactionUiState,
} from './ConversationContext.types'

import { ConversationContext } from './ConversationContext'

type Props = {
  children: React.ReactNode
}

const ConversationProvider = ({ children }: Props) => {
  const [conversation, setConversation] = useState<Conversation>()
  const [conversationUi, setConversationUi] = useState<GetConversationUiState>({
    uiState: UiState.Idle,
    error: null,
    status: null,
  })

  const [transaction, setTransaction] = useState<TransactionModel | null>(null)
  const [transactionUi, setTransactionUi] = useState<GetTransactionUiState>({
    uiState: UiState.Idle,
    error: null,
  })

  const [sendMessageUi, setSendMessageUi] = useState<SendMessageUiState>({ uiState: UiState.Idle })
  const [isFirstTimeListerEducationDialogVisible, setIsFirstTimeListerEducationDialogVisible] =
    useState(false)

  const conversationError = conversationUi.error
  const conversationUiState = conversationUi.uiState || UiState.Idle
  const conversationRequestStatus = conversationUi.status

  const getTransactionRequest = useCallback(async id => {
    setTransactionUi({ uiState: UiState.Pending, error: null })
    const response = await getTransaction({ id })

    if ('errors' in response) {
      setTransactionUi({ uiState: UiState.Failure, error: response.errors[0]?.value || null })

      return
    }

    const transactionResponse = transformTransaction(response.transaction)

    setTransactionUi({ uiState: UiState.Success, error: null })
    setTransaction(transactionResponse)
  }, [])

  const setConversationSuccess = useCallback(val => {
    setConversation(val)
    setConversationUi({ uiState: UiState.Success, error: null, status: HttpStatus.Ok })
  }, [])

  const setTransactionSuccess = useCallback(val => {
    setTransaction(val)
    setTransactionUi({ uiState: UiState.Success, error: null })
  }, [])

  const getConversationRequest = useCallback(
    async ({ conversationId, fromPostReply = false }: GetConversationRequestArgs) => {
      setConversationUi({
        uiState: UiState.Pending,
        status: null,
        error: null,
      })
      if (!fromPostReply) setConversation(undefined)

      if (Number.isNaN(Number(conversationId))) {
        // TODO: backend returns 200 when passed a string. Should be fixed in backend
        setConversationUi({ uiState: UiState.Failure, error: null, status: HttpStatus.NotFound })
        setConversation(undefined)

        return
      }
      const response = await api.getConversation(conversationId)

      if ('errors' in response) {
        setConversationUi({
          uiState: UiState.Failure,
          error: response.errors[0]?.value || null,
          status: response.status,
        })
        setConversation(undefined)

        return
      }

      const transformedConversation = transformConversationResponse(response.conversation)

      if (transformedConversation.transactionId)
        getTransactionRequest(transformedConversation.transactionId)

      setConversationUi({ uiState: UiState.Success, error: null, status: response.status })
      setConversation(transformedConversation)

      if (transformedConversation.isFirstTimeListerEducationRequired)
        setIsFirstTimeListerEducationDialogVisible(true)
    },
    [setIsFirstTimeListerEducationDialogVisible, getTransactionRequest],
  )

  const setIsFirstTimeListerEducationDialogVisibleCallback = useCallback(
    val => {
      setIsFirstTimeListerEducationDialogVisible(val)
    },
    [setIsFirstTimeListerEducationDialogVisible],
  )

  const createItemConversationThreadRequest = useCallback(
    async ({ itemId, sellerId, initiator }) => {
      setConversationUi({
        uiState: UiState.Pending,
        status: null,
        error: null,
      })

      const response = await createItemConversationThread({
        itemId,
        initiator,
        receiverId: sellerId,
      })

      if ('errors' in response) {
        setConversationUi({
          uiState: UiState.Failure,
          error: response.errors[0]?.value || null,
          status: response.status,
        })

        return
      }

      if (!('conversation' in response)) return

      setConversationUi({ uiState: UiState.Success, error: null, status: response.status })
      setConversation(transformConversationResponse(response.conversation))
    },
    [],
  )

  const clearConversation = useCallback(() => {
    setConversation(undefined)
    setConversationUi({
      uiState: UiState.Idle,
      status: null,
      error: null,
    })
  }, [])

  const translateConversationRequest = useCallback(
    async ({ conversationId, translate }: TranslateConversationArgs) => {
      const response = await translateConversation({
        conversationId,
        translate,
      })

      if ('errors' in response) return

      setConversation(transformConversationResponse(response.conversation))
      setConversationUi({
        uiState: UiState.Success,
        status: response.status,
        error: null,
      })
    },
    [],
  )

  const contextValue = useMemo(
    () => ({
      conversation,
      conversationUiState,
      conversationRequestStatus,
      conversationError,
      getConversationRequest,
      setConversation: setConversationSuccess,
      setTransaction: setTransactionSuccess,
      clearConversation,
      translateConversationRequest,
      transaction,
      transactionUiState: transactionUi.uiState,
      createItemConversationThreadRequest,
      sendMessageUi,
      setSendMessageUi,
      isFirstTimeListerEducationDialogVisible,
      setIsFirstTimeListerEducationDialogVisible:
        setIsFirstTimeListerEducationDialogVisibleCallback,
    }),
    [
      conversation,
      conversationUiState,
      conversationRequestStatus,
      conversationError,
      getConversationRequest,
      setConversationSuccess,
      setTransactionSuccess,
      clearConversation,
      translateConversationRequest,
      transaction,
      transactionUi,
      createItemConversationThreadRequest,
      sendMessageUi,
      setSendMessageUi,
      isFirstTimeListerEducationDialogVisible,
      setIsFirstTimeListerEducationDialogVisibleCallback,
    ],
  )

  return (
    <ConversationContext.Provider value={contextValue}>{children}</ConversationContext.Provider>
  )
}

export default ConversationProvider
