'use client'

import { Cell, Image } from '@vinted/web-ui'
import { useIntl } from 'react-intl'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import { CurrencyAmountModel } from 'types/models'

type Props = {
  price: CurrencyAmountModel
  thumbnailUrl: string | null | undefined
  title?: string
  itemCount?: number
}

const ItemSummary = ({ title, thumbnailUrl, itemCount = 1, price }: Props) => {
  const { locale } = useIntl()
  const translate = useTranslate('conversation.hero_message.make_offer.offer_modal')

  const isBundle = itemCount > 1

  const renderPhotoLabel = () => {
    return isBundle ? itemCount.toString() : undefined
  }

  const renderPrice = () => {
    const translationKey = isBundle ? 'bundle_price' : 'item_price'

    return translate(translationKey, {
      price: formatCurrencyAmount(price, locale),
    })
  }

  const renderTitle = () => {
    if (isBundle) return translate('items_count', { count: itemCount }, { count: itemCount })

    return title
  }

  if (!thumbnailUrl) return null

  return (
    <Cell
      title={renderTitle()}
      body={renderPrice()}
      prefix={
        <div>
          <Image
            src={thumbnailUrl}
            styling={Image.Styling.Rounded}
            size={Image.Size.Large}
            scaling={Image.Scaling.Cover}
            ratio={Image.Ratio.Square}
            label={renderPhotoLabel()}
          />
        </div>
      }
    />
  )
}

export default ItemSummary
