import { CheckoutOrderTypeMap, ExtraServiceOrderType } from 'constants/extra-service'
import { initiateSingleCheckout } from 'data/api'

import { navigateToSingleCheckout } from '_libs/utils/checkout'

import { useAbTest, useTrackAbTestCallback } from '@marketplace-web/shared/ab-tests'

const useNavigateToSingleCheckout = () => {
  const singleCheckoutEscrowAbTest = useAbTest('single_checkout_escrow')
  const shouldRedirectToNew = singleCheckoutEscrowAbTest?.variant === 'on'
  const trackExpose = useTrackAbTestCallback()

  const navigateToNewCheckout = async (transactionId: number) => {
    if (!shouldRedirectToNew) return null

    const orderType = CheckoutOrderTypeMap[ExtraServiceOrderType.Transaction]

    const response = await initiateSingleCheckout({
      id: transactionId.toString(),
      type: orderType,
    })

    if ('errors' in response) {
      return null
    }

    return navigateToSingleCheckout(response.checkout.id, transactionId, orderType)
  }

  const trackSingleCheckoutABExpose = () => {
    trackExpose(singleCheckoutEscrowAbTest)
  }

  return { shouldRedirectToNew, navigateToNewCheckout, trackSingleCheckoutABExpose }
}

export default useNavigateToSingleCheckout
