'use client'

import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'

import { navigateToPage } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useSession } from '@marketplace-web/shared/session'
import { UiState } from '@marketplace-web/shared/ui-helpers'

import {
  makeOfferEvent,
  openOfferScreenEvent,
  startConversationEvent,
} from '_libs/common/event-tracker/events'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import { Screen } from 'constants/tracking/screens'
import { CONVERSATION_URL } from 'constants/routes/inbox'
import { CUSTOM_OFFER_PRICE_OPTION_INDEX } from 'constants/conversation'
import { createOfferRequest, getOfferRequestOptions } from 'data/api'
import { OfferPriceSuggestionOption } from 'types/conversation'
import { OfferRequestOptionsModel } from 'types/models'

import { useConversationContext } from '@marketplace-web/domain/core-conversation'

import { transformOfferRequestOptions } from 'data/transformers/offer'

import BuyerOfferRequestDialog from '../../BuyerOfferRequestDialog'
import { OfferFormField, OfferFormModel } from '../../types'
import BuyerOfferRequestsExceededModal from '../../BuyerOfferRequestsExceededModal'

type CreateOfferUiState = {
  uiState: UiState
  error?: string
}

type Props = {
  isOpen: boolean
  currency: string
  onClose: () => void
  itemInfo: {
    itemId: string
    itemIds: Array<number>
    itemTitle?: string
    itemThumbnailUrl: string | null
    itemPrice: string
    sellerId: number
  }
}

// TODO: Refactor this component to be used with single and multiple items
const ItemBuyerOfferModal = ({
  isOpen,
  itemInfo: { itemId, itemIds, itemTitle, itemThumbnailUrl, itemPrice, sellerId },
  onClose,
  currency,
}: Props) => {
  const formMethods = useForm<OfferFormModel>()
  const { reset, setError, getValues, handleSubmit } = formMethods

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { track } = useTracking()
  const { locale } = useIntl()
  const session = useSession()

  const screenName = session.screen
  const userId = session.user?.id

  const { conversation, transaction } = useConversationContext()

  const [createOfferUi, setCreateOfferUi] = useState<CreateOfferUiState>({
    uiState: UiState.Idle,
  })
  const [offerRequestSessionId, setOfferRequestSessionId] = useState<string>()
  const [offerRequestOptions, setOfferRequestOptions] = useState<OfferRequestOptionsModel>()
  const [selectedOfferRequestPriceSuggestion, setSelectedOfferRequestPriceSuggestion] =
    useState<OfferPriceSuggestionOption>()

  const offerPriceSuggestions = offerRequestOptions?.offerSuggestions
  const transactionId = conversation?.transactionId || transaction?.id

  const handleError = useCallback(
    (message: string) => {
      setError(OfferFormField.Offer, { type: 'manual', message })
      setIsSubmitting(false)
    },
    [setError],
  )

  useEffect(() => {
    if (!isOpen) return

    setOfferRequestSessionId(uuid())
  }, [isOpen, setOfferRequestSessionId])

  useEffect(() => {
    if (!isOpen) return

    reset({
      [OfferFormField.Offer]: '',
    })
  }, [reset, isOpen, offerRequestOptions])

  useEffect(() => {
    if (!isOpen) return

    const fetchOfferRequestOptions = async () => {
      const response = await getOfferRequestOptions({
        itemPrice: { amount: itemPrice, currencyCode: currency },
        sellerId,
      })

      if ('errors' in response) {
        return
      }

      const transformedOfferRequestOptions = transformOfferRequestOptions(response.request_options)

      setOfferRequestOptions(transformedOfferRequestOptions)

      const isOfferGuidanceEnabled = !!response.request_options.offer_suggestions

      if (isOfferGuidanceEnabled) {
        setSelectedOfferRequestPriceSuggestion({ isCustom: true })
      }
    }

    fetchOfferRequestOptions()
  }, [sellerId, itemPrice, currency, isOpen])

  const isBundlingScreen = screenName === Screen.Bundling

  useEffect(() => {
    if (isBundlingScreen) return

    if (createOfferUi.uiState === UiState.Success && conversation && userId) {
      track(startConversationEvent({ userId, itemId }))

      navigateToPage(CONVERSATION_URL(conversation.id))
    }

    if (createOfferUi.uiState === UiState.Failure && createOfferUi.error) {
      handleError(createOfferUi.error)
    }
  }, [userId, itemId, track, handleError, conversation, createOfferUi, isBundlingScreen])

  useEffect(() => {
    if (!isBundlingScreen) return

    if (createOfferUi.uiState === UiState.Success && transaction?.conversationId && userId) {
      navigateToPage(CONVERSATION_URL(transaction.conversationId))
    }

    if (createOfferUi.uiState === UiState.Failure && createOfferUi.error) {
      handleError(createOfferUi.error)
    }
  }, [
    userId,
    handleError,
    createOfferUi.error,
    createOfferUi.uiState,
    transaction?.conversationId,
    isBundlingScreen,
  ])

  useEffect(() => {
    if (!isOpen || !offerPriceSuggestions) return undefined

    return () => {
      setSelectedOfferRequestPriceSuggestion({
        isCustom: true,
      })
    }
  }, [isOpen, offerPriceSuggestions, setSelectedOfferRequestPriceSuggestion])

  useEffect(() => {
    if (!transactionId || !isBundlingScreen) return

    track(
      openOfferScreenEvent({
        isBuyer: true,
        currentPrice: Number(itemPrice),
        currencyCode: currency,
        openedFrom: Screen.BundleSummary,
        transactionId,
      }),
    )
  }, [transactionId, isBundlingScreen, track, itemPrice, currency])

  async function handleFormSubmit() {
    if (!transactionId) return

    setIsSubmitting(true)

    const formattedOfferValue = getValues().offer.replace(',', '.')

    const selectedOfferPriceSuggestionIndex = offerPriceSuggestions?.findIndex(
      suggestion => suggestion.price === selectedOfferRequestPriceSuggestion?.suggestion?.price,
    )
    const optionIndex = selectedOfferRequestPriceSuggestion?.isCustom
      ? CUSTOM_OFFER_PRICE_OPTION_INDEX
      : selectedOfferPriceSuggestionIndex

    setCreateOfferUi({ uiState: UiState.Pending })

    const response = await createOfferRequest({
      transactionId,
      price: formattedOfferValue,
      currency,
    })

    if ('errors' in response) {
      setCreateOfferUi({
        uiState: UiState.Failure,
        error: response?.errors?.[0]?.value,
      })

      return
    }

    const offerRequestId = 'offer_request' in response && response.offer_request.id
    const offerSuggestionOptionNumber = optionIndex ? optionIndex + 1 : null

    const offerEvent = makeOfferEvent({
      isBuyer: true,
      transactionId,
      offerRequestSessionId,
      offerSuggestionOptionNumber,
      offerRequestId: offerRequestId || undefined,
      offeredPrice: Number(formattedOfferValue),
      currentPrice: Number(itemPrice),
    })

    track(offerEvent)
    setCreateOfferUi({ uiState: UiState.Success })
  }

  function handleOnClose() {
    if (isSubmitting) return

    onClose()
  }

  const remainingOffers = offerRequestOptions?.remainingOfferCount

  if (remainingOffers === 0) {
    return (
      <BuyerOfferRequestsExceededModal
        show={isOpen}
        onClose={onClose}
        maxOfferCount={offerRequestOptions?.maxOfferCount}
      />
    )
  }

  if (!offerRequestOptions || !remainingOffers) return null

  const { feesReductionThreshold } = offerRequestOptions

  const thresholdPrice = feesReductionThreshold
    ? formatCurrencyAmount(feesReductionThreshold, locale)
    : null

  return (
    <FormProvider {...formMethods}>
      <BuyerOfferRequestDialog
        show={isOpen}
        isSubmitting={isSubmitting}
        minItemPrice={offerRequestOptions.minPrice}
        maxItemPrice={offerRequestOptions.maxPrice}
        maxDiscount={offerRequestOptions.maxDiscount}
        itemPrice={{
          amount: itemPrice,
          currencyCode: currency,
        }}
        itemId={Number(itemId)}
        itemTitle={itemTitle}
        itemThumbnailUrl={itemThumbnailUrl}
        orderItemIds={itemIds}
        thresholdPrice={thresholdPrice}
        remainingOffers={remainingOffers}
        offerPriceSuggestions={offerPriceSuggestions}
        onSubmit={handleSubmit(handleFormSubmit)}
        onClose={handleOnClose}
        transactionId={transactionId}
        offerRequestSessionId={offerRequestSessionId}
        selectedOfferRequestPriceSuggestion={selectedOfferRequestPriceSuggestion}
        onSelectOfferRequestPriceSuggestion={setSelectedOfferRequestPriceSuggestion}
      />
    </FormProvider>
  )
}

export default ItemBuyerOfferModal
