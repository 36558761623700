import { OfferDto, OfferRequestOptionsDto, OfferRequestPriceSuggestionDto } from 'types/dtos'
import {
  OfferModel,
  OfferRequestOptionsModel,
  OfferRequestPriceSuggestionModel,
} from 'types/models'

import { transformCurrencyAmountDto, transformCurrencyAmountDtoOrString } from './currency-amount'

export const transformOfferDto = ({ id, price, currency }: OfferDto): OfferModel => ({
  id,
  price: transformCurrencyAmountDtoOrString(price, currency),
})

export const transformOfferPriceSuggestion = ({
  label,
  price,
}: OfferRequestPriceSuggestionDto): OfferRequestPriceSuggestionModel => ({
  label,
  price: transformCurrencyAmountDto(price),
})

export const transformOfferRequestOptions = (
  response: OfferRequestOptionsDto,
): OfferRequestOptionsModel => ({
  maxDiscount: response.max_discount,
  canMakeMore: response.can_make_more,
  maxOfferCount: response.max_offer_count,
  remainingOfferCount: response.remaining_offer_count,
  minPrice: transformCurrencyAmountDto(response.min_price),
  maxPrice: transformCurrencyAmountDto(response.max_price),
  offerSuggestions: response.offer_suggestions?.map(transformOfferPriceSuggestion) || null,
  feesReductionThreshold: response.fees_reduction_threshold
    ? transformCurrencyAmountDto(response.fees_reduction_threshold)
    : null,
})
